import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['ButtonDialog_sample1', 'ButtonDialog_sample2', 'ButtonDialog_sample3', 'ButtonDialog_sample4', 'ButtonDialog_sample5'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const Typography = makeShortcode("Typography");
const SaveTextField = makeShortcode("SaveTextField");
const ButtonDialog = makeShortcode("ButtonDialog");
const Paper = makeShortcode("Paper");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "adressing-misconceptions"
    }}>{`Adressing misconceptions`}</h2>
    <p>{`When talking with your teen about the pandemic, you may need to address any misconceptions about the pandemic that are worrying them. Below are some common questions or misconceptions that could come up when talking with your teen. Pick a couple of questions below that you think your teen might raise, and write down how you might respond. Then, click the button to show some suggested responses.`}</p>
    <Grid container spacing={2} justify="center" mdxType="Grid">
  <Grid item xs={12} md={10} mdxType="Grid">
    <Typography mdxType="Typography">
      “If I’m not likely to get sick or die from COVID-19, why do I have stay home and follow all of these rules? It’s
      unfair!”
    </Typography>
  </Grid>
  <Grid item xs={12} md={10} mdxType="Grid">
    <SaveTextField id="pandemicAddressMisconception1" variant="outlined" label="Insert text" mdxType="SaveTextField" />
  </Grid>
  <Grid item xs={12} md={10} mdxType="Grid">
    <ButtonDialog linkText="See sample response" title="Sample response" id="sample1" mdxType="ButtonDialog">
      It’s understandable to feel frustrated and that the pandemic and restrictions aren’t fair. Following the rules can
      be boring and difficult. However, it’s important that we all do our part to protect the community. Even though
      teenagers don’t usually get very sick with COVID, you could be one of the unlucky ones that does, or you could
      spread to other people. What you do can help to save other people’s lives.
    </ButtonDialog>
  </Grid>
  <Grid item xs={12} md={10} mdxType="Grid">
    <Typography mdxType="Typography">“My friends aren’t following the rules, why should I?”</Typography>
  </Grid>
  <Grid item xs={12} md={10} mdxType="Grid">
    <SaveTextField id="pandemicAddressMisconception2" variant="outlined" label="Insert text" mdxType="SaveTextField" />
  </Grid>
  <Grid item xs={12} md={10} mdxType="Grid">
    <ButtonDialog linkText="See sample response" title="Sample response" id="sample2" mdxType="ButtonDialog">
      It’s frustrating when you’re trying to do the right thing and others are not, especially when they are your
      friends. Regardless of what other people are doing, it’s important to follow the official advice and do the right
      thing. Set an example for your friends by following the rules yourself. You might even find they change their
      mind.
    </ButtonDialog>
  </Grid>
  <Grid item xs={12} md={10} mdxType="Grid">
    <Typography mdxType="Typography">
      “With all the financial impacts of the pandemic, I won’t get to pursue the degree or career that I want.”
    </Typography>
  </Grid>
  <Grid item xs={12} md={10} mdxType="Grid">
    <SaveTextField id="pandemicAddressMisconception3" variant="outlined" label="Insert text" mdxType="SaveTextField" />
  </Grid>
  <Grid item xs={12} md={10} mdxType="Grid">
    <ButtonDialog linkText="See sample response" title="Sample response" id="sample3" mdxType="ButtonDialog">
      The uncertainty of the pandemic can be unsettling. Even if you feel that you’ve missed out on a lot, there will be
      other opportunities in the future. Try to focus on what you can control right now, and work towards achievable
      goals.
    </ButtonDialog>
  </Grid>
  <Grid item xs={12} md={10} mdxType="Grid">
    <Typography mdxType="Typography">“I’m wasting my life staying at home all the time and not spending it with my friends.”</Typography>
  </Grid>
  <Grid item xs={12} md={10} mdxType="Grid">
    <SaveTextField id="pandemicAddressMisconception4" variant="outlined" label="Insert text" mdxType="SaveTextField" />
  </Grid>
  <Grid item xs={12} md={10} mdxType="Grid">
    <ButtonDialog linkText="See sample response" title="Sample response" id="sample4" mdxType="ButtonDialog">
      It’s frustrating to miss out on things you’d like to be doing. Try to find creative ways to keep doing the things
      you enjoy and stay connected with your friends.
    </ButtonDialog>
  </Grid>
  <Grid item xs={12} md={10} mdxType="Grid">
    <Typography mdxType="Typography">“When will this pandemic end?” or “When will life go back to normal?”</Typography>
  </Grid>
  <Grid item xs={12} md={10} mdxType="Grid">
    <SaveTextField id="pandemicAddressMisconception5" variant="outlined" label="Insert text" mdxType="SaveTextField" />
  </Grid>
  <Grid item xs={12} md={10} mdxType="Grid">
    <ButtonDialog linkText="See sample response" title="Sample response" id="sample5" mdxType="ButtonDialog">
      Unfortunately we just don’t know yet. What we do now, including following the local regulations, will help life
      get back to normal as soon as possible. In the meantime, try to keep up with as many regular activities as
      possible, even if online or virtual.
    </ButtonDialog>
  </Grid>
    </Grid>
    <Paper elevation={0} style={{
      backgroundColor: '#bce2e3',
      borderRadius: '20px'
    }} mdxType="Paper">
  The pandemic situation and local regulations are constantly changing. You don’t have to have it all figured out.
  <ul>
    <li>
      If there are questions you cannot answer, be honest and let your teen know that you will try your best to find out
      the answers, and then make time to talk with them again.
    </li>
    <li>
      If you can’t find out the answers, acknowledge that some questions cannot be answered at this time because we are
      still learning about the pandemic.
    </li>
  </ul>
    </Paper>
    <b>
  <i>
    Remember, it’s not all about the pandemic - keep up the normal everyday conversations and activities with your teen.
  </i>
    </b>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      